@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  cursor: default;
}

body.dashboard-bg {
  background-color: #f0f2f5;
}
body.dashboard-bg {
  overflow: auto !important;
}
header {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 35px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 2;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 100%;
  padding: 0 60px;
}

.login-form {
  width: 100%;
  max-width: 340px;
  background: #fff;
  border-radius: 8px;
  position: relative;
  /*overflow: hidden;*/
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  padding: 20px 0;
}

.login-form:before {
  width: 94%;
  height: 8px;
  background: #fff;
  content: "";
  position: absolute;
  z-index: 0;
  padding-bottom: 5px;
  bottom: -8px;
  border-radius: 0 0 8px 8px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.6;
}

.login-form:after {
  width: 88%;
  height: 8px;
  background: #fff;
  content: "";
  position: absolute;
  z-index: 0;
  padding-bottom: 5px;
  bottom: -16px;
  border-radius: 0 0 8px 8px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
}

.login-bg form {
  padding: 30px;
}

.login-header {
  padding: 20px 20px 0;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 40px;
}

.login-header img {
  max-width: 140px;
  margin: 0 auto 15px;
  display: block;
}

.login-form .btn {
  width: 100%;
  background: linear-gradient(145deg, #2c8aea, #2574c5);
  border-color: #2981db;
  height: 44px;
  max-width: 320px;
  margin: 30px auto 15px;
  display: block;
  box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
  transition: all ease-in 300ms;
}

.login-form .btn:hover {
  box-shadow: none;
}

a.navbar-brand img {
  max-width: 130px;
  width: 100%;
}
.login-form a {
  color: #2981db !important;
  text-decoration: underline !important;
  font-size: 15px;
  font-weight: normal;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.login-form label.form-label {
  line-height: 1;
  font-size: 14px;
}

.next-arrow input.form-control {
  padding-right: 30px;
}

.next-arrow span {
  display: block;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #6c7592;
  border-bottom: 10px solid transparent;
  border-radius: 4px;
}

.next-arrow {
  position: relative;
}

.login-form .input.form-control {
  height: 44px;
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
}

.login-form.welcome p span {
  display: block;
  font-size: 24px;
  color: #68d0c4;
}

.login-form.welcome p {
  color: #8e8e8e;
  text-align: center;
  margin-bottom: 25px;
}

button.btn.btn-primary.secondary-btn {
  background: linear-gradient(145deg, #6fdfd2, #5ebbb0);
  border-color: #68d0c4;
  box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
  transition: all ease-in 300ms;
}

button.btn.btn-primary.secondary-btn:hover {
  box-shadow: none;
}

.login-form.welcome .btn {
  margin: 15px auto 15px;
}

/*=========== Dashboard ===========*/
.bg-light {
  background-color: #ffffff !important;
}

.navbar-brand {
  margin-right: 70px;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

header .nav-link {
  padding: 1.25rem 0 !important;
  font-size: 13px;
  color: #666;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgb(41 129 219);
  border-bottom: 2px solid;
}

header li.nav-item {
  padding: 0 8px;
}

button.btn.btn-outline-success.search-btn img {
  width: 16px;
}

button.btn.btn-outline-success.search-btn {
  border: none;
  padding-left: 0;
}

.search-form input.form-control {
  background: transparent;
  border: none;
  margin-right: 0 !important;
  max-width: 160px;
  font-size: 13px;
}

form.d-flex.search-form {
  border-bottom: 1px solid #d1d1d1;
}

.user-account {
  margin-left: 70px;
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;
}

.user-account span img {
  max-width: 24px;
}

.user-account span {
  font-size: 13px;
  color: #8e8e8e;
}

.container-fluid {
  padding: 0 15px;
}

.dashboard-main {
  padding: 15px 0;
}

.patient-enroll {
  background: #fff;
  border-radius: 4px;
}

.patient-enroll h3 {
  font-size: 14px;
  border-bottom: 1px solid #efefef;
  display: block;
  padding: 10px 15px;
  color: #666666;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0;
}

.patient-enroll span {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #68d0c4;
  font-weight: 500;
}

ul.stats-tab {
  padding: 0;
  display: flex;
  gap: 10px;
  width: 100%;
}

ul.stats-tab li {
  width: 100%;
  box-shadow: 0 2px 4px #ababab24;
  background: #fff;
  border-radius: 4px;
}

.task-to-do {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px #ababab24;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.task-to-do::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.task-to-do {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*.task-to-do h2 {
    font-size: 16px;
    text-transform: uppercase;
}*/

.task-to-do h2,
.patient-alert h2 {
  font-size: 14px;
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #efefef;
  text-transform: uppercase;
  line-height: 1;
}

ul.task-list {
  margin: 0;
  padding: 20px;
  height: 100%;
  overflow-y: hidden;
  max-height: calc(100vh - 180px);
  transition: all ease-in 300ms;
}

ul.task-list {
  overflow-y: auto;
}

ul.task-list li {
  padding: 20px;
  box-shadow: 0px 4px 16px rgb(177 177 177 / 30%);
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all ease-in 0.3s;
  border: 1px solid #efefef;
  // cursor: pointer;
}

// ul.task-list li:hover {
//     background: #efefef;
//     box-shadow: none;
// }

.details h4 {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}

.details small {
  font-size: 12px;
  color: #b2b2b2;
}

.details b {
  font-weight: normal;
  color: #626c75;
  margin-left: 10px;
}

ul.task-list .btn {
  height: 36px !important;
  font-size: 12px;
  padding: 0 20px;
  background: #2981db;
  border-color: #2981db;
}

.patient-alert {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px #ababab24;
  height: 100%;
}

.patient-alert ul {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: hidden;
  max-height: calc(100vh - 180px);
  transition: all ease-in 300ms;
}

.patient-alert ul:hover {
  overflow-y: auto;
}

.patient-alert ul h3 {
  font-size: 13px;
  margin-bottom: 10px;
  color: #131313;
  font-weight: 500;
  line-height: 20px;
}

.patient-alert ul h3 small {
  font-size: 10px;
  padding-left: 6px;
  color: #8e8e8e;
  font-weight: normal;
}

.patient-alert ul p {
  font-size: 12px;
  margin-bottom: 10px;
  color: #474747;
}

.patient-alert ul a {
  font-size: 12px;
  font-weight: 500;
  color: #3c8cde;
}

.patient-alert ul li {
  margin-bottom: 0;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  padding: 20px;
  transition: all ease-in 0.3s;
}

.patient-alert ul li:hover {
  background: #efefef;
}

.patient-alert h2 img {
  max-width: 20px;
  margin-right: 2px;
}

.patient-alert h2 {
  font-size: 12px;
  padding: 10px 15px;
  line-height: 1;
}

/*===========  patient table  ==========*/

.patient-table,
.patient-form,
.crf-list {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px #ababab24;
}

.patient-table th,
.patient-form th,
.epro-table th {
  background: #f7f7f7 !important;
  border-bottom-width: 0 !important;
}

.patient-table td,
.patient-form td,
.patient-table th,
.patient-form th,
.epro-table th {
  font-size: 14px;
  font-weight: normal;
  padding: 25px 15px;
}

.epro-table td {
  font-size: 12px;
  font-weight: normal;
  padding: 20px 15px;
  color: #676767;
}

.patient-table th,
.patient-form th,
.epro-table th {
  color: #8e8e8e;
}

.patient-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.patient-table-header button.nav-link {
  font-size: 13px;
  color: #666;
}

.patient-table-header .nav-pills .nav-link.active {
  color: #2981db;
  background-color: transparent;
  border-bottom: 1px solid;
  border-radius: 0;
  text-transform: uppercase;
}

img.next-arrow-icon {
  max-width: 20px;
  margin: 0 auto;
}

td.incomplete {
  color: #e91e63;
}

td.complete {
  color: #4caf50;
}

.patient-form h2 {
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #686868;
}

form.patient-form-area {
  display: flex;
  gap: 25px;
  max-width: 970px;
  margin-top: 15px;
  margin-bottom: 0;
}

tr.active.incomplete-status td {
  font-weight: 600;
}

form.patient-form-area input.form-control,
form.patient-form-area .form-select {
  font-size: 12px;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0;
  padding: 10px 0;
}

form.patient-form-area button.btn.btn-primary {
  font-size: 12px;
  height: 36px;
  padding: 0.375rem 1.75rem;
  background: #2981db;
  border-color: #2981db;
}

form.patient-form-area select.form-select {
  min-width: 140px;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

tr.active td:first-child {
  border-left: 4px solid #2981db;
}

.patient-form th,
.patient-table th,
.epro-table th {
  padding: 15px 15px;
}

/*=================  Breadcrumb  ====================*/

.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0;
  color: #6c757d;
  content: "";
  width: 6px;
  height: 6px;
  background: transparent;
  transform: rotate(45deg);
  border-top: 1px solid #6c757d;
  border-right: 1px solid #6c757d;
  position: absolute;
  top: 5px;
  left: 8px;
}

.custom-breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 24px;
}

.custom-breadcrumb li.breadcrumb-item {
  position: relative;
  font-size: 11px;
}

.custom-breadcrumb li.breadcrumb-item a {
  font-size: 12px;
  color: #212529;
  text-decoration: none;
  letter-spacing: 0.5px;
}

/*=================  Custom Select  =================*/

select.custom-select {
  font-size: 12px;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0;
  // min-width: 140px;
  background: transparent;
}

/*====================  CRF List  ===========================*/

.crf-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 30px;
}

.crf-list-header h2 small {
  font-size: 10px;
  display: block;
  color: #8e8e8e;
  text-transform: uppercase;
  margin-bottom: 0;
}

.crf-list-header h2 {
  font-size: 16px;
  margin: 0;
  line-height: 24px;
}

.action-button img {
  max-width: 20px;
  margin-right: 8px;
}

.action-button button.btn.btn-primary {
  background: #fff;
  border-color: #fff;
  box-shadow: 4px 4px 8px #d9d9d9, -4px -4px 8px #ffffff;
  margin: 0;
  color: #666;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  transition: all ease-in 0.3s;
}

.action-button button.btn.btn-primary:hover {
  box-shadow: none;
  border: 1px solid #efefef;
}

.action-button {
  display: flex;
  gap: 15px;
}

.crf-list-header .nav-tabs .nav-link.active {
  color: #2981db;
  border: none;
  text-transform: uppercase;
  transition: all ease-in 300ms;
  border-bottom: 1px solid #2981db;
}

.crf-list-header .nav-tabs .nav-link {
  padding: 8px 5px;
  font-size: 12px;
  color: #666;
}

.crf-list-header .nav-tabs {
  border-bottom: none;
  display: flex;
  gap: 10px;
}

.crf-list-header .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.crf-list .tab-content {
  padding: 20px;
}

.crf-list-parameter .form-group {
  width: calc(33.3% - 20px);
}

.crf-list-parameter {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.crf-list-parameter label {
  font-size: 12px;
  color: #8e8e8e;
  display: block;
}

.crf-list-parameter input.form-control {
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0px;
  padding: 6px 6px 6px 0;
  color: #111 !important;
}

.type-box img {
  max-width: 32px;
}

.type-box button.btn.btn-primary {
  background-color: transparent;
  border-color: transparent;
}

.type-box {
  display: flex;
}

.chat-box {
  width: 100%;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  padding: 30px;
  border-radius: 4px;
  display: block;
  margin-top: 50px;
}

.recieve-msg {
  display: flex;
  max-width: 75%;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}

.recieve-msg span {
  width: 32px;
  height: 32px;
  display: flex;
  background: #e9fbff;
  border-radius: 16px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: #2981db;
}

.recieve-msg p {
  display: inline-block;
  background: #e9fbff;
  border-radius: 16px;
  font-size: 12px;
  padding: 7px 14px;
  color: #2981db;
}

.type-box input.form-control {
  font-size: 13px;
}

.sender-msg p {
  display: inline-block;
  background: #e9fbff;
  border-radius: 16px;
  font-size: 12px;
  padding: 7px 14px;
  color: #2981db;
}

.sender-msg {
  display: flex;
  max-width: 75%;
  align-items: flex-start;
  gap: 10px;
  justify-content: flex-end;
  margin-left: auto;
  margin-bottom: 15px;
}

/*===================  EPRO VIEW  ==================*/

.patient-name {
  display: flex;
  gap: 30px;
}

.epro-detail-box {
  padding: 25px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
  border-radius: 4px;
}

.epro-detail-box label {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: #8a8a8a;
  padding: 15px 0;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
}

.epro-detail-box label:nth-child(6) {
  border-bottom: 0;
}

.epro-detail-box label span {
  width: 40%;
}

label b {
  color: #333333;
  font-weight: normal;
}

.epro-detail-box textarea {
  display: block;
  width: 100%;
  border: 1px solid #efefef;
  min-height: 100px;
  margin-top: 10px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 6px;
}

.epro-detail-box button.btn.btn-primary {
  font-size: 12px;
  height: 36px;
  padding: 0.375rem 1.75rem;
  background: #2981db;
  border-color: #2981db;
  width: 100px;
  display: block;
  margin: 10px 0 0 auto;
}

.epro-detail h5,
.epro-table h5 {
  font-size: 12px;
  margin: 0;
  padding: 20px;
  color: #8a8a8a;
  font-weight: normal;
  text-transform: uppercase;
}

/*==============  Current Status  ===============*/

.current-bar,
.expected-bar {
  display: flex;
  align-items: baseline;
  gap: 20px;
  padding: 10px 0;
}

.current-bar label,
.expected-bar label {
  font-size: 12px;
  color: #8a8a8a;
  text-transform: uppercase;
  width: 57px;
  text-align: right;
}
.progress.current,
.progress.expected {
  display: flex;
  height: 10px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px;
  width: 100%;
}
.dates span {
  position: relative;
  padding-top: 15px;
}

.dates span:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #42a5ff;
  position: absolute;
  top: 0;
  left: 0;
}

.dates span:nth-child(2):after {
  left: auto;
  right: 0;
}

.current-bar .progress-bar {
  background-color: #68d0c4;
}

.expected-bar .progress-bar {
  background-color: #42a5ff;
}

.status-bars {
  padding: 20px;
}

input.form-control {
  font-size: 14px;
}

.select-dashboard select.custom-select {
  font-size: 15px;
  padding: 10px 0;
  border: none;
  border-bottom: none;
  border-radius: 0;
  min-width: 160px;
  color: #2981db;
  background: transparent;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0;
  cursor: pointer;
}

.sub-header {
  padding: 15px 0 0;
  max-width: 100%;
  margin: 0 auto;
}

.breadcrumb {
  margin-bottom: 10px;
}

::-moz-placeholder {
  opacity: 1;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1 !important;
  color: #f30;
}

.navbar-light .navbar-nav .nav-link {
  color: #666;
  cursor: pointer;
}

/***  Material Table Start ***/

mat-header-row {
  background: #f0f2f5 !important;
}

.patient-table-header {
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.patient-table-header button {
  background: transparent;
  border: none;
  padding: 6px 12px;
  font-size: 13px;
  color: #333333;
  transition: all ease 0.3s;
}

.patient-table-header button.active {
  color: #42a5ff;
  text-transform: uppercase;
}

mat-row,
mat-header-row,
mat-footer-row {
  border-bottom-width: 0;
}

mat-row {
  cursor: pointer;
}

mat-cell,
mat-header-cell,
mat-footer-cell {
  justify-content: flex-start;
}

/***  Material Table End ***/

// =============  Scroll bar Design  =============

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #42a5ff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #42a5ff;
}

.mat-dialog-title {
  font-size: 18px !important;
}

.mat-dialog-actions {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  box-sizing: content-box;
  margin-bottom: 0 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #42a5ff;
}

.mat-paginator-container {
  justify-content: space-between !important;
}

.spacing {
  width: 100%;
  padding: 31px;
}

::placeholder {
  color: #333;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #333;
}

.mat-cell,
.mat-footer-cell {
  color: #333;
}

mat-header-row {
  border-bottom-width: 0;
}

ul.task-list.show-two {
  max-height: 280px;
}

.adverse-summary.mat-dialog-content img {
  max-width: 320px;
  margin: 30px auto 10px;
  display: block;
}

.adverse-summary.mat-dialog-content {
  border: 1px solid #efefef;
}

.cdk-overlay-pane.adverse-detail {
  padding: 0;
  box-sizing: border-box;
  border-radius: 8px;
}

.adverse-detail .mat-dialog-container {
  padding: 0;
}

.adverse-detail .mat-dialog-content {
  margin: 0;
  padding: 16px;
  overflow: visible;
  border-radius: 4px;
}

.adverse-detail .mat-dialog-container {
  box-shadow: none;
  overflow: visible;
}

.adverse-detail {
  position: relative !important;
}
.adverse-detail:after {
  content: "";
  background: url(/assets/shadow.png);
  width: 100%;
  height: 89px;
  position: absolute;
  bottom: -36px;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.3;
}

.adverse-summary {
  position: relative;
  display: flex;
  flex-direction: column;
}

.adverse-summary label {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
}

.adverse-summary label span {
  width: 130px;
}

.adverse-summary label b {
  width: calc(100% - 140px);
}

.adverse-summary span.close-popup {
  background: #2981db;
  border-radius: 20px;
  padding: 7px;
  border: 2px solid #2981db;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
  opacity: 1;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: visible;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.adverse-summary .close-popup:before,
.adverse-summary .close-popup:after {
  position: absolute;
  left: 13px;
  content: " ";
  height: 14px;
  width: 2px;
  background-color: #fff;
}
.adverse-summary .close-popup:before {
  transform: rotate(45deg);
}
.adverse-summary .close-popup:after {
  transform: rotate(-45deg);
}

.bm-list {
  max-height: 300px;
  overflow-y: hidden;
  transition: all ease-in 300ms;
}

.bm-list:hover {
  overflow-y: auto;
}

.cal-week-view .cal-current-time-marker {
  background-color: #ea4334;
  display: none;
}

.mat-paginator-container {
  border-top: 1px solid #efefef;
}

// calendar css
.cal-hour-segment:before {
  content: "Double click to create appointment" !important;
  position: absolute !important;
  background: #e4e4e4 !important;
  z-index: 1 !important;
  width: 120px !important;
  padding: 6px 10px !important;
  border-radius: 6px !important;
  top: -33px !important;
  display: none !important;
  left: 50% !important;
  transform: translate(-50%, 0px) !important;
  text-align: center !important;
}

.cal-hour-segment:hover:before {
  display: block !important;
}
.cal-month-view .cal-cell-top {
  position: relative;
}

.cal-month-view .cal-cell-top:before {
  position: absolute;
  content: "Double click to create appointment";
  width: 100%;
  height: 100%;
  background: #d0d0d0;
  text-align: center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.cal-month-view .cal-cell-top:hover:before {
  display: block;
}
#transition-popper {
  z-index: 99999;
}
mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-past.cal-in-month {
  opacity: 0.5;
  pointer-events: none;
}
mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-past.cal-out-month {
  opacity: 0.5;
  pointer-events: none;
}

.disabled-date {
  opacity: 0.5;
  pointer-events: none;
}
// css video zoom
.video-div * {
  transition: none !important;
}
#meetingSDKElement .react-draggable {
  height: 100%;
}

#meetingSDKElement .react-draggable .react-resizable {
  height: 100%;
}

#meetingSDKElement .react-draggable .react-resizable > div:first-child {
  height: calc(100% - 40px);
}

#meetingSDKElement .react-draggable .react-resizable > div:first-child div#suspension-view-tabpanel-active {
  height: calc(100% - 40px);
}

#meetingSDKElement .react-draggable .react-resizable > div:first-child div#suspension-view-tabpanel-active > div {
  width: 100%;
  height: 100%;
  max-height: 390px;
  align-items: center;
  justify-content: center;
}

#meetingSDKElement
  .react-draggable
  .react-resizable
  > div:first-child
  div#suspension-view-tabpanel-active
  > div
  > div:last-child {
  width: 100%;
  height: 100%;
}
#meetingSDKElement
  .react-draggable
  .react-resizable
  > div:first-child
  div#suspension-view-tabpanel-active
  > div
  > div:last-child
  li {
  min-width: 100%;
  min-height: 100%;
  left: 0 !important;
}
.zmwebsdk-makeStyles-participantsCustomize-2.zmwebsdk-makeStyles-participantsCustomize-9 {
  z-index: 9999 !important;
}
.zmwebsdk-makeStyles-meetingCustomize-5.zmwebsdk-makeStyles-meetingCustomize-12 {
  z-index: 99999999 !important;
}
video#ZOOM_WEB_SDK_SELF_VIDEO{
  top: 8px !important;
  left: 8px !important;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}
video.custom{
  top: 8px !important;
  left: 8px !important;
  width: 25% !important;
  height: 40% !important;
  display: block;
  position: absolute;
  z-index: 9999;
}
// css video zoom close
